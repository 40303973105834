import { useState, useContext } from 'react';
import styled from 'styled-components';
import {GiHamburgerMenu} from 'react-icons/gi';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import Col from '../Col';
import Row from '../Row';

const Container = styled(Col)`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 30px;
  z-index: 20;

  &:hover {
    cursor: pointer;
  }
`

const MenuIcon = styled(GiHamburgerMenu)`
  width: 100%;
  height: 100%;
`

const MenuPopup = styled(Col)`
  display: ${props => props.opened ? "flex" : "none"};
  align-items: center;
  position: fixed;
  top: 77px;
  transform: translate(-50%) translate(12px);
  width: ${props => props.width}px;
  background: #FFFFFF;
  border: 0.25px solid #000000;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`

const MenuLink = styled(Link)`
  font-size: 15px;
  height: 40px;
  width: 100%;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: black;
  &:hover {
    background-color: #eee;
  }
`

const MenuItem = styled(Row)`
  font-size: 15px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  justify-content: space-around;
  background-color: ${props => props.bc || "white"};
`

const MenuGroup = styled(Col)`
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  align-items: center;
  border-bottom: 0.25px solid #5A5A5A;
`

function UserMenu() {
  const { authState, setAuth } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();

  const handleLogout = () => {
    setAuth({...authState, isAuthed: false});
    navigate("/")
  }

  return (
    <Container onClick={() => setOpen(!open)}>
      <MenuIcon />
      {
        authState.isAuthed ? 
          <AuthedMenu 
            user={authState.user} 
            open={open} 
            handleLogout={handleLogout}/> :
          <UnAuthedMenu open={open} />
      }
    </Container>
  )
}

function AuthedMenu(props) {
  const { user, open, handleLogout } = props;

  return (
    <MenuPopup width={200} opened={open}>
      <MenuGroup>
        {/* logo */}
        <MenuItem>
          {user.name} 님
        </MenuItem>
      </MenuGroup>
      <MenuGroup>
        별조각: 26개
      </MenuGroup>
      <MenuGroup>
        <MenuLink to="/mypage"> My Page </MenuLink>
        <MenuLink to="/mypage"> 내 작품 관리하기 </MenuLink>
        <MenuLink to="/register"> 새 소설 등록하기 </MenuLink>
      </MenuGroup>
      <MenuItem onClick={handleLogout} bc="#D9D7D7">로그아웃</MenuItem>
    </MenuPopup>
  )
}

function UnAuthedMenu(props) {
  const { open } = props;
  return (
    <MenuPopup width={150} opened={open}>
      <MenuLink to="/login">로그인</MenuLink>
      <MenuLink to="/join">회원가입</MenuLink>
    </MenuPopup>
  )
}

export default UserMenu;