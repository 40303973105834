import { createContext, useState } from "react";

const defaultAuthValue = {
  user: {
    name: "",
    email: "",
    id: "",
  }, 
  isAuthed: false,
  token: "",
};

const AuthContext = createContext(defaultAuthValue);

function AuthProvider(props) {
  const [ authState, setAuth ] = useState(defaultAuthValue);

  return (
    <AuthContext.Provider value={{authState, setAuth}}>
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider };