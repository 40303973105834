import { useContext } from "react";
import styled from 'styled-components';

import { ep } from '../../api/util';
import { AuthContext } from '../../context/authContext'

import BasicFrame from "../../frame/Basic";
import Text from "../../components/Text";
import Col from "../../components/Col";
import Row from "../../components/Row";
import Spacing from "../../components/Spacing";
import Link from '../../components/Link';

import Recent from './Recent';
import Serial from './Serial';

const Button = styled(Col)`
  width: 200px;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
`;

const OptionWrapper = styled(Row)`
  position: relative;
  border-bottom: 2px solid #000000;
  width: 100%;
`

const OptionButton = styled(Col)`
  align-items: center;
  justify-content: center;
  width: 176px;
  height: 76px;
  outline: ${props => props.outline?"2px solid black":undefined};
`

const White = styled.div`
  position: absolute;
  width: 176px;
  height: 5px;
  left: ${props => {
    let idx = 0, val;
    if(props.type === "recent") idx = 0;
    else if(props.type === "serial") idx=1;
    else if(props.type === "profit") idx=2;
    else if(props.type === "bill") idx=3;
    else if(props.type === "contract") idx=4;
    val = 176 * idx;
    console.log(val, idx);
    return `${val}px`;
  }};
  background: white;
  z-index: 10;
  bottom: -3px;
`

function MyPage({type}) {
  const { authState } = useContext(AuthContext);
  const links = [
    {url: '/mypage/recent', type: 'recent', name: '기록', element: <Recent />},
    {url: '/mypage/serial', type: 'serial',name: '연재', element: <Serial />},
    {url: '/mypage/profit', type: 'profit', name: '수익'},
    {url: '/mypage/bill', type: 'bill', name: '결제'},
    {url: '/mypage/contract', name: '계약'},
  ]

  return (
    <BasicFrame>
      <Col width="112px" gap="16px" ai="center" jc="center">
        <img src={ep(`/static/img/user/${authState.user.id}`)} width={80} height={80} />
        <Text align="center" normal width="112px">{authState.user.name}님의 페이지</Text>
      </Col>
      <Spacing height="52px" />
      <Button ai="center" jc="center"><Text small>회원 정보 열람</Text></Button>
      <Spacing height="60px" />
      <OptionWrapper ai="center">
        <White type={type} />
        {
          links.map(l => (
            <Link big weight={700} to={l.url}>
              <OptionButton outline={type === l.type}>{l.name}</OptionButton>
            </Link>
          ))
        }
      </OptionWrapper>
      <Spacing height="60px" />
      <Serial />
    </BasicFrame>
  )
}

export default MyPage;