import styled from 'styled-components'

const Container = styled.div`
  background-color: ${props => props.bc};
  color: ${props => props.c};
  min-width: 36px;
  padding: 2px 5px;
  text-align: center;
  line-height: 26px;
  border-radius: 12px;
`

function Badge({bc, c, text}) {
  return (
    <Container bc={bc} c={c}>
      {text}
    </Container>
  )
}

export default Badge;