import { Link } from 'react-router-dom';
import { ReactComponent as SiteIcon } from '../../assets/logo4.svg';
import styled from 'styled-components';

import Col from '../Col';
import Row from '../Row';
import Spacing from '../Spacing';
import UserMenu from './UserMenu';

const Title = styled(Link)`
  width: 129px;
  height: 50px;
  margin-left: 10px;
  line-height: 50px;
  font-size: 30px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  text-align: center;
`

const MenuLink = styled(Link)`
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  color: black;
  font-size: ${props => props.selected && 25}px;
`

const Logo = styled(SiteIcon)`
  width: 76px;
  height: 50px;
`

function TopBar() {
  return (
    <Col width="100%" bc="white" ai="center">
      <Spacing height="32px" />
      <Row width="1256px" jc="space-between" ai="center">
        <Row>
          <Logo />
          <Title to="/">베타노블</Title>
        </Row>
        <Row ai="center" gap="60px">
          <MenuLink to="/relay">릴레이</MenuLink>
          <MenuLink to="/d">공모전</MenuLink>
          <MenuLink to="/d">거래소</MenuLink>
          <UserMenu />
        </Row>
      </Row>
      <Spacing height="32px" />
    </Col>
  )
}

export default TopBar;