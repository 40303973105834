import { useState } from 'react';
import styled from 'styled-components';
import { useRef } from 'react';

import Col from '../../components/Col';

const Container = styled(Col)`
  width: 100%;
  height: 300px;
  padding-top: 112px;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  margin-bottom: 60px;
`

const Button = styled.div`
  width: 150px;
  height: 75px;
  text-align: center;
  line-height: 75px;
  font-size: 25px;
  background-color: #8CCDA3;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 25px;
  color: white;
  &:hover {
    cursor: pointer;
  }
`

const Info = styled.div`
  font-size: 20px;
  width: 490px;
  text-align: center;
  font-weight: medium;
`

// TODO: change on file uploaded?

function FileUpload(props) {
  const [state, setState] = useState("empty") // "empty" || "hover" || "file"
  const hiddenFileInput = useRef(null);

  const parser = (files) => {
    if(files.length > 0) {
      let reader = new FileReader();
      reader.onload = (evt) => {
        let buf = evt.target.result.split(',')[1];
        let decode = Buffer.from(buf, 'base64').toString('utf-8');
        props.setContent(decode);
      }

      reader.readAsDataURL(files[0]);
    }
  }

  const dropHandler = (evt) => {
    let files = evt.dataTransfer.files;
    parser(files);
    evt.preventDefault();
  }

  const dragOverHandler = (evt) => {
    evt.preventDefault();
  }

  const handleClick = (evt) => {
    hiddenFileInput.current.click();
  }

  const handleChange = (evt) => {
    let files = evt.target.files;
    parser(files);
  }

  if(state === 'empty') {
    return(
      <Container onDrop={dropHandler} onDragOver={dragOverHandler}>
        <input type="file" 
              style={{display: 'none'}} 
              ref={hiddenFileInput} 
              onChange={handleChange}/>
        <Button onClick={handleClick}> 파일 선택 </Button>
        <Info>*업로드 할 파일을 선택하거나 업로드 영역으로 드래그 엔 드롭 해주세요. 파일을 업로할 시, 사이트 내에서 작성한 글은 도전 등록에 적용되지 않습니다.</Info>
      </Container>
    )
  } else if(state === 'hover') {
    
  }
}

export default FileUpload;