import styled from 'styled-components';

import Badge from './Badge';
import Col from './Col';
import Row from './Row';
import Text from './Text';

const Container = styled(Col)`
  position: ${props => props.popup?'fixed':undefined};
  top: ${props => props.Y}px;
  left: ${props => props.X}px;
  width: ${props => props.width || 565}px;
  gap: 12px;
  padding: 25px 15px;
  z-index: 10;

  background: #FFFFFF;
  opacity: 0.95;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`

function NovelInfo({popup, pos, width, novel}) {
  console.log('NovelInfo() ', novel);
  
  return (
    <Container 
      popup={popup} 
      width={width} 
      X={pos.X} Y={pos.Y} 
      jc="space-between">
      <Col gap="20px">
        <Row ai="flex-end" gap="12px">
          <Text big sbb>{novel.title}</Text>
          <Text small sbm>{novel.writer}</Text>
        </Row>
        <Text tiny>{novel.introduction}</Text>
        <Row gap="12px">
          {
            novel.tags.map(tag => <Badge bc="#9FE3B7" c="black" text={tag}/>)
          }
        </Row>
      </Col>
      <Row jc="space-around">
        <Text tiny>조회수 {novel.view}회</Text>
        <Text tiny>회차 {novel.episodeCount}회</Text>
        <Text tiny>좋아요 {novel.like}개</Text>
      </Row>
    </Container>
  )
}

export default NovelInfo;