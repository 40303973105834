import styled from 'styled-components'
import Spacing from './Spacing';

export default styled(Spacing)`
  width: ${props => props.width};
  margin: ${props => props.margin};
  display: flex;
  flex-direction: column;
  align-items: ${props => props.ai};
  gap: ${props => props.gap};
  align-self: ${props => props.als};
  justify-content: ${props => props.jc};
`