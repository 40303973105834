const _EpisodeInfo = {
  id: "",
  novelId: "",
  title: "",
  writer: "",
  date: "",
  view: 0,
  like: 0,
  commentCount: 0
}

const _EpisodeContent = {
  id: "",
  novelId: "",
  pages: [""],
}

const _Novel = {
  id: "",

  title: "",
  writer: "",
  img: "",
  introduction: "",
  category: "",
  tags: [""],
  isProfit: false,
  isMonopoly: false,
  cycle: [""], 
  opened: false,

  view: 0,
  like: 0,
  episodeCount: 0,
  state: "voting"|"challenging"|"complete",
  episodeIds: [""],
}

const _Section = {
  title: "",
  novelIds: [""],
  showNumber: false,
}

export {
  _Novel,
  _Section,
  _EpisodeInfo,
  _EpisodeContent
};