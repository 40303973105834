import styled from 'styled-components'
import { Link } from 'react-router-dom';

import Badge from './Badge';
import Row from './Row';

const Container = styled(Row)`
  width: ${props => props.width}px;
  justify-content: space-between;
  border-bottom: 1px solid black;
  margin-bottom: 43px;
`

const Wrapper = styled(Row)`
  align-items: center;
  gap: 16px;
`

const Title = styled.div`
  margin-left: 6px;
  font-size: 25px;
  font-family: 'SBB';
  font-weight: bold;
`

const Content = styled.div`
  font-size: 15px;
  color: #434343;
`

const MoreLink = styled(Link)`
  color: black;
  font-size: 10px;
`

function AlarmBar({title, width, content}) {
  return (
    <Container width={width}>
      <Wrapper>
        <Title>{title}</Title>
        <Badge text="UP" bc="#ff2626" c="white" />
        <Content>{content}</Content>
      </Wrapper>
      <Wrapper>
        <MoreLink to="/">자세히보기</MoreLink>
      </Wrapper>
    </Container>
  )
}

export default AlarmBar;