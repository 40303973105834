import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.c || 'black'};
  font-size: ${props => props.huge?40:props.big?32:props.chubby?28:props.normal?24:props.small?20:props.tiny?16:props.pico?12:props.nano?8:16}px;
  font-family: ${props => props.sbb?'SBB':props.sbm?'SBM':undefined};
  font-weight: ${props => props.weight || 500};
`

export default StyledLink;