import { post, ep } from './util';

async function getToken(email, password) {
  try {
    const data = await post(ep('/v1/login'), {email, password})
    return await data.json();
  } catch(err) {
    return false;
  }
}

async function join(email, password, name, phonenumber) {
  try {
    const data = await post(ep('/v1/join'), {email, password, name, phonenumber});
    return await data.json();
  } catch(err) {
    return false;
  }
}

export { getToken, join }