import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components';

import { AuthContext } from '../../context/authContext';
import Protected from '../../components/Protected'
import Col from '../../components/Col';
import Row from '../../components/Row';
import FullFrame from '../../frame/Full';
import { join } from '../../api/auth';
import AddFriend from '../../assets/icon/add-friend.png';

const Logo = styled.img`
  width: 63px;
  height: 63px;
  margin-bottom: 10px;
`

const Title = styled.div`
  font-size: 22px;
  color: #4B4B4B;
  font-family: SBB;
  margin-bottom: 16px;
`

const Consent = styled.div`
  font-size: 12px;
  margin-bottom: 7px;
`

const ConsentLink = styled(Link)`
  text-decoration: none;
  color: #00B3E5;
  font-weight: bold;
`

const Button = styled.div`
  width: 43px;
  height: 22px;
  background: #00B3E5;
  border-radius: 10px;
  font-size: 12px;
  color: white;
  text-align: center;
  line-height: 22px;

  &:hover {
    cursor: pointer;
  }
`

function Insert() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [name, setName] = useState("");
  const [pn, setPn] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [passMiss, setPassMiss] = useState(false);

  let navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);

  useEffect(() => {
    // check name dup
  }, [name]);

  useEffect(() => {
    console.log(password, confirm, password === confirm);
    setPassMiss(password === "" || password !== confirm);
  }, [password, confirm]);

  useEffect(() => {
    setShowMsg(password !== "")
  }, [password])

  const handleJoin = async () => {
    if(password === confirm) {
      const data = await join(email, password, name, pn);
      console.log(data);

      if(data === false || data.err === true) {
        // retry!
      } else {
        setAuth({
          user: {
            name, 
            email, 
            id: data.id
          }, 
          isAuthed: true,
          token: data.token, 
        });
        navigate("/");
      }
    }
  }

  return (
    <Protected>
    <FullFrame width="628">
      <Logo src={AddFriend}/>
      <Title>베타노블 계정 생성</Title>
      <InputFeild label="이메일" value={email} setValue={setEmail} />
      <InputFeild type="password" label="비밀번호" value={password} setValue={setPassword} />
      <InputFeild 
        type="password"
        label="비밀번호 확인" 
        value={confirm} 
        setValue={setConfirm} 
        isWrong={passMiss}
        msg={showMsg}/>
      <InputFeild label="닉네임" value={name} setValue={setName} />
      <InputFeild label="휴대폰번호" value={pn} setValue={setPn} last={true} />
      <Row>
        <input type="checkbox" />
        <Consent>
          베타노블 웹사이트 <ConsentLink to="/join/terms">약관</ConsentLink>에 동의합니다.
        </Consent>
      </Row>
      <Button onClick={handleJoin}>다음</Button>
    </FullFrame>
    </Protected>
  )
}

const Container = styled(Col)`
  margin-bottom: ${props => props.isLast?"44px":"11px"};
`

const Label = styled(Row)`
  font-size: 13px;
  justify-content: space-between;
`

const ColorText = styled.div`
  color: ${props => props.color};
`

const Input = styled.input`
  outline: none;
  width: 479.92px;
  height: 22.93px;
  background: #FAFAFA;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 10px;
`

function InputFeild(props) {
  let isLast = props.last;
  return (
    <Container isLast={isLast}>
      <Label>
        {props.label}
        {props.msg && Msg(props)}
      </Label>
      <Input type={props.type || "text"}
             value={props.value} 
             onChange={(evt) => props.setValue(evt.target.value)} />
    </Container>
  )
}

function Msg(props) {
  return (
      <>
      {
        props.isWrong ?
          <ColorText color="#FF4E4E">비밀번호가 일치하지 않습니다</ColorText> : 
          <ColorText color="#4EC653">비밀번호가 일치합니다</ColorText>
      }
      </>
    );
}

export default Insert;