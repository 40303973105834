function ep(path) {
  return `${process.env.REACT_APP_API_ENDPOINT}${path}`;
}

async function get(url) {
  return fetch(url, {method: 'GET'});
}

async function post(url, payload) {
  return fetch(url, {
    method: 'POST', 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

export { 
  ep, 
  get, 
  post
};