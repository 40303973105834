import styled from "styled-components";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import Row from './Row'
import NovelInfo from './NovelInfo'
import { useEffect } from "react/cjs/react.development";
import { getNovel } from "../api";
import { ep } from '../api/util';
import { _Novel } from '../api/schema';
import Text from './Text';

const Container = styled(Row)`
  position: relative;
  flex-direction: column;
  width: 180px;
  &:hover{cursor:pointer;}
`

const Cover = styled.img`
  width: 180px;
  height: 270px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  border-radius: 5px;
  margin-bottom: 7px;
  object-fit: cover;
`

const Number = styled(Text)`
  position: absolute;
  top: 8px;
  left: 8px;
`

function WorkCard({id, showNumber, number}) {
  const [hover, setHover] = useState(false);
  const [pos, setPos] = useState({X:0, Y:0});
  const [novel, setNovel] = useState(_Novel);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getNovel(id);
      console.log('WorkCard() fetchData ', id, data);
      setNovel(data);
      setLoading(false);
    } 

    fetchData();
  }, [id]);

  // loading
  if(loading) 
    return (
      <Container></Container>
    )
  else 
    return(
      <Container
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseMove={(evt) => setPos({X: evt.clientX, Y: evt.clientY})}
        onClick={() => navigate(`/novel/${id}`)}>
        <Cover src={ep(`/static/img/${novel.img}`)} />
        {
          showNumber && <Number normal c="white" weight={700}> {number} </Number>
        }
        <Text tiny>{novel.title}</Text>
        <Text pico>{novel.writer}</Text>
        {
          hover && <NovelInfo popup pos={pos} novel={novel} />
        }
      </Container>
    )
}

export default WorkCard;