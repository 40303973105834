import styled from 'styled-components';

import Row from '../../components/Row';
import Col from '../../components/Col';

let CATEGORY_DAY = ["월", "화", "수", "목", "금", "토", "일"];
let CATEGORY_GEN = ["판타지", "로맨스", "무협", "학원", "현대", "일상", "공포", "중세", "코미디", "패러디"];

const Container = styled(Col)`
  width: 1128px;
  margin-top: 32px;
`

const Wrapper = styled(Row)`
  width: 100%;
  justify-content: ${props => props.center?"center":"space-between"};
  border-bottom: ${props => props.border && "2px solid black"};
  margin-top: ${props => props.top || "0"}px;
`

const Option = styled.div`
  position: relative;
  color: ${props => props.on ? 'black':'#C4C4C4'};
  font-size: ${props => props.size}px;
  font-family: 'SBB';
  text-align: center;
  line-height: 38px;

  &:hover {cursor:pointer;}
`

const Sort = styled(Option)`
  width: 70px;
  font-size: 16px;
  text-align: center;
  height: 33px;
  padding-bottom: 1px;
  outline: ${props => props.on && "2px solid black"};

  /* ${props => props.on && `
    &::after {
      content: '';
      position: absolute;
      width: 70px;
      height: 5px;
      bottom: -4px;
      right: 0px;
      background: white;
    }
  `}; */

  &:last-of-type {
    margin-right: 32px;
  }
`

const Color = styled.span`
  color: ${props => props.color};
`

const Label = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
`

const CheckBox = styled.input`
  width: 12px;
  height: 12px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
`

function OptionBar(props) {

  let {
    isDay, setIsDay, 
    selected, setSelected, 
    isFree, setIsFree, 
    category, setCategory,
    selectedCat, setSelectedCat } = props;
  
  return (
    <Container>
      <Wrapper border={true}>
        <Row gap="52px">
          <Option 
            size="22" 
            on={isDay} 
            onClick={() => {
              setIsDay(true);
              setCategory(CATEGORY_DAY);
              setSelectedCat(0);
            }}>
              요일
          </Option> 
          <Option 
            size="22" 
            on={!isDay} 
            onClick={() => {
              setIsDay(false);
              setCategory(CATEGORY_GEN);
              setSelectedCat(0);
            }}>
              카테고리
          </Option> 
        </Row>
        <Row gap="12px">
          <Sort size="16" on={selected === 'time'} onClick={() => setSelected('time')}>시간순</Sort>
          <Sort size="16" on={selected === 'like'} onClick={() => setSelected('like')}>좋아요</Sort>
          <Sort size="16" on={selected === 'view'} onClick={() => setSelected('view')}>조회수</Sort>
          <Option size="22" on>
            <Color color="#61B47E">
              {props.workNum}
            </Color>
            개의 작품
          </Option>
        </Row>
      </Wrapper>
      <Wrapper>
        <Row gap="12px">
          <Option 
            size="16" 
            on={isFree} 
            onClick={() => setIsFree(true)}>
              자유
          </Option> 
          <Option 
            size="16" 
            on={!isFree} 
            onClick={() => setIsFree(false)}>
              정식
          </Option> 
        </Row>
        <Row gap="12px">
          <Row ai="center">
            <CheckBox type='checkbox' />
            <Label>전체</Label>
          </Row>
          <Row ai="center">
            <CheckBox type='checkbox' />
            <Label>연재</Label>
          </Row>
          <Row ai="center">
            <CheckBox type='checkbox' />
            <Label>완결</Label>
          </Row>
        </Row>
      </Wrapper>
      <Wrapper top="0" center>
        <Row gap={isDay?"94px":"42px"}>
          {
            category.map((cat, idx) => 
              <Option 
                key={cat}
                size="24" 
                on={idx === selectedCat}
                onClick={() => setSelectedCat(idx)}
              > {cat} </Option>)
          }
        </Row>
      </Wrapper>
    </Container>
  )
}

export default OptionBar;
export { CATEGORY_DAY }