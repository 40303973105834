import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../context/authContext';

function Protected(props) {
  const { authState } = useContext(AuthContext);
  let navigate = useNavigate();
  
  useEffect(() => {
    if(authState.isAuthed)
      navigate("/");
  }, [authState, navigate])

  return (
    <>
      {
        props.children
      }
    </>
  )
}

export default Protected;