import styled from 'styled-components';
import { useEffect, useState } from 'react';

import BasicFrame from '../../frame/Basic';

import FileUpload from './FileUpload';
import Text from '../../components/Text'

const TitleInput = styled.input`
  width: 100%;
  margin: 18px 0 32px;
  padding-bottom: 12px;
  font-size: 32px;
  border: none;
  overflow: auto;
  outline: none;
  border-bottom: 1px solid black;
`

const ContentInput = styled.textarea`
  width: 100%;
  min-height: 700px;
  font-size: 20px;
  resize: vertical;
  font-weight: regular;
  font-family: Roboto, sans-serif;
  border: none;
  overflow: auto;
  outline: none;
  border-bottom: 1px solid black;
`

const ReviewInput = styled(ContentInput)`
  min-height: 300px;
`

const ContentInfo = styled.div`
  align-self: flex-end;
  font-size: 18px;
  margin-bottom: 18px;
`

const Title = styled(Text)`
  align-self: flex-start;
  margin-bottom: 11px;
`;

const SubmitButton = styled.div`
  width: 150px;
  height: 50px;
  margin: 28px 0;
  text-align: center;
  line-height: 50px;
  background-color: #8CCDA3;
  color: white;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  align-self: flex-end;
  &:hover {
    cursor: pointer;
  }
`

function Editor() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [len, setLen] = useState(0);
  const [review, setReview] = useState("");

  useEffect(() => {
    setLen(content ? content.length : 0);
  }, [content]);

  return (
    <BasicFrame>
      <TitleInput 
        placeholder="제목을 입력하세요" 
        value={title}
        onChange={(evt) => {
          setTitle(evt.target.value);
        }} />
      <ContentInput 
        placeholder="내용 입력란" 
        value={content}
        onChange={(evt) => {
          setContent(evt.target.value);  
        }} />
      <ContentInfo>
        전체 글자 : {len}자
      </ContentInfo>
      <Title big sbm>파일 업로드</Title>
      <FileUpload setContent={setContent}/>
      <Title big sbm>작가 후기</Title>
      <ReviewInput placeholder="내용 입력란"
        value={review}
        onChange={(evt) => {
          setReview(evt.target.value);
        }} />
      <SubmitButton>등록</SubmitButton>
    </BasicFrame>
  )
}

export default Editor;