import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import BasicFrame from '../../frame/Basic';
import NovelInfo from '../../components/NovelInfo';
import EpisodeList from './EpisodeList';
import NovelImg from './NovelImg';
import Row from '../../components/Row';
import Spacing from '../../components/Spacing';
import { ep } from '../../api/util';

import { getNovel, getNovelAlarm } from '../../api';
import { _Novel } from '../../api/schema';

function Novel() {
  let { novelId } = useParams();
  const [novel, setNovel] = useState(_Novel);
  const [alarm, setAlarm] = useState({title: "", content: ""});
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const novel = await getNovel(novelId);
      const res = await getNovelAlarm();
      setNovel(novel);
      setAlarm(res);
      setBusy(false);
    }

    fetchData();
  }, [novelId]);

  console.log(novel);

  if(busy)
    return (<div></div>)
  else
    return (
      <BasicFrame>
        <Row gap="16px">
          <NovelImg src={ep(`/static/img/${novel.img}`)} state={novel.state} />
          <NovelInfo pos={{X: false, Y: false}} width="892" novel={novel} />
        </Row>
        <Spacing height="24px" width="100%"/>
        <EpisodeList episodeIds={novel.episodeIds} state={novel.state}/>
      </BasicFrame>
  )
}

export default Novel;