import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';

import Col from '../../components/Col';
import Row from '../../components/Row';
import Text from '../../components/Text';
import StyledLink from '../../components/Link';
import ClockImg from '../../assets/img/clock.png';
import { _EpisodeInfo } from '../../api/schema';
import { getEpisodeInfo } from '../../api';

const Controller = styled(Row)`
  width: 1128px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
`

const EpisodeLink = styled(Col)`
  width: 1000px;
  border-bottom: 1px solid black;
`

function EpisodeList({episodeIds, count, state}) {
  const [episodes, setEpisodes] = useState([_EpisodeInfo]);
  const [incr, setIncr] = useState(true);
  let msg = {
    'voting': '투표중',
  }[state];

  useEffect(() => {
    const fetchEpisodeInfo = async () => {
      setEpisodes(await getEpisodeInfo(episodeIds));
    }

    fetchEpisodeInfo();
  }, [])

  useEffect((episodes) => {
    if(episodes) setEpisodes(episodes.reverse());
  }, [incr], episodes);

  return (
    <Col ai="center">
      <Controller gap="20px">
        <Row gap="12px">
          <Col>
            <img src={ClockImg} width='26px' />
            <Text nano sbm>{msg}</Text>
          </Col>
          <Text c='red' sbm small>00시간 57분 47초</Text>
        </Row>
        <Row gap='20px'>
          <Text small>일괄구매</Text>
          <Row onClick={() => setIncr(incr => !incr)}>
            <Text small>{incr ? "첫회부터":"마지막회부터"}</Text>
            {incr ? <BsArrowDown size={24}/> : <BsArrowUp size={24}/>}
          </Row>
        </Row>
      </Controller>
      <Col width="1000px">
        {
          episodes.map(({id, title, date, writer}) => 
            <EpisodeLink  gap="20px"  padding="5px 0">
              <StyledLink to={`/viewer/${id}`} chubby="true" weight="500">{title}</StyledLink>
              <Row gap="12px">
                <Text small>{writer}</Text>
                <Text small c="#9C9C9C">{date}</Text>
              </Row>
            </EpisodeLink> 
          )
        }
      </Col>
    </Col>
  )
}

export default EpisodeList;