import styled from 'styled-components';
import Spacing from '../../components/Spacing';
import Text from '../../components/Text';
import Row from '../../components/Row';

const Banner = styled(Row)`
  position: absolute;
  transform: rotate(-45deg);
  height: 45px;
  width: 189.78px;
  background: #FFFB8F;
  text-align: center;
  overflow:hidden;
  transform-origin: center;
  top: 30px;
  left: -50px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
`

const Img = styled.img`
  width: 220px;
  height: 330px;
  border-radius: 10px;
  object-fit: cover;
`

const NovelImg = ({src, state}) => {
  const msg = {
    'voting': '투표중',
    'challenging': '도전중',
  }[state];

  return (
    <Spacing position="relative" width="220px" height="330px" overflow="hidden">
      <Img src={src}/>
      <Banner align="center" justify="center">
        <Text normal weight="bold" >{msg}</Text>
      </Banner>
    </Spacing>
  )
}

export default NovelImg;