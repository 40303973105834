import { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components';

import { ReactComponent as SiteIcon } from '../../assets/logo4.svg';
import Row from '../../components/Row';
import Col from '../../components/Col';
import FullFrame from '../../frame/Full';
import { AuthContext } from '../../context/authContext';
import { getToken } from '../../api/auth';
import { 
  UserLogo,
  NaverLogo,
  GoogleLogo,
  KakaoLogo,
  EmailLogo,
} from '../../components/Logo';

const Logo = styled(SiteIcon)`
  width: 80px;
  height: 52px;
  margin-bottom: 7px;
`

const Field = styled(Col)`
  &::first-child {
    margin-bottom: 20px;
  }
  margin-bottom: 8px;
`

const CField = styled(Row)`
  flex-direction: ${props => props.reverse?'row-reverse':'row'};
  width: 354px;
  justify-content: space-between;
  margin-bottom: ${props => props.margin}px;
`

const CRow = styled(Row)`
  align-items: center;
  height: fit-content;
`

const TextInput = styled.input`
  width: 354px;
  height: 20px;
  outline: none;
  font-size: 15px;
  line-height: 20px;

  background: #FFFFFF;
  opacity: 0.7;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
`

const Label = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: ${props => props.isCorrect?'none':'box'};
  color: ${props => props.color || 'black'};
  font-family: ${props => props.noFont?"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;":'SBL'};
`

const CheckBox = styled.input`
  width: 8px;
  height: 8px;
`

const FindPw = styled(Link)`
  margin-left: 16px;
  text-decoration: none;
  font-size: 12px;
  color: #00B3E5;
`

const LoginButton = styled.div`
  width: 103px;
  height: 22px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  line-height: 22px;
  background-color: #8CCDA3;
  border-radius: 10px;
  color: white;
  &:hover {
    cursor: pointer;
  }
`

const LoginService = styled(Row)`
  justify-content: center;
  align-items: center;
  width: 380px;
  height: 33px;
  color: ${props => props.color || "white"};
  font-family: Roboto;
  background-color: ${props => props.bc || "white"};
  font-size: 13px;
  text-align: center;
  line-height: 33px;
  margin-bottom: 16px;
  font-weight: bold;
  &:hover {cursor:pointer;}
  &:last-child {margin-bottom: 0;}
`

const LoginLink = styled(Link)`
  color: ${props => props.color || "white"};
`

const ServiceLogo = styled.img`
  height: 18px;
  margin-right: 16px;
`

function Login() {
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [passWrong, setPassWrong] = useState(false);
  const [autoLogin, setAutoLogin] = useState(false);
  let navigate = useNavigate();

  const { authState, setAuth } = useContext(AuthContext);

  const handleLogin = async () => {
    const data = await getToken(email, pw);
    console.log(data);

    if(data === false || data.err === true) {
      setPassWrong(true);
    } else {
      setAuth({...data, isAuthed: true});
      navigate("/");
    }
  }

  useEffect(() => {
    if(authState.isAuthed)
      navigate("/");
  }, [authState, navigate])

  return (
    <FullFrame width="628">
      <Logo />
      <Field>
        <Label>
          이메일
        </Label>
        <TextInput type="text" 
                  value={email} 
                  onChange={evt => setEmail(evt.target.value)} />
      </Field> 
      <Field>
        <Label>
          비밀번호
        </Label>
        <TextInput type="password" 
                  value={pw} 
                  onChange={evt => setPw(evt.target.value)} />
      </Field>
      <CField margin="10">
        <CRow>
          <CheckBox type="checkbox" 
                    checked={autoLogin} 
                    onChange={_ => setAutoLogin(!autoLogin)} />
          <Label noFont={true}>
            자동로그인
          </Label>
          <FindPw to="/findpw">
            비밀번호 찾기
          </FindPw>
        </CRow>
      </CField>
      <CField margin="52" reverse={true}>
        <LoginButton onClick={handleLogin}>로그인</LoginButton>
        <Label noFont={true} 
              color="#FF4E4E" 
              isCorrect={!passWrong}>
          비밀번호 혹은 이메일이 틀렸습니다
        </Label>
      </CField>
      <LoginService bc="#03C75A">
        <ServiceLogo src={NaverLogo} />
        네이버 로그인
      </LoginService>
      <LoginService bc="#FEE500">
        <ServiceLogo src={KakaoLogo} />
        카카오 로그인
      </LoginService>
      <LoginService bc="#F16438">
        <ServiceLogo src={GoogleLogo} />
        구글 로그인
      </LoginService>
      <LoginService>
        <LoginLink to="/join" color="#00B3E5">
          회원가입
        </LoginLink>
      </LoginService>
    </FullFrame>
  )
}

export default Login;