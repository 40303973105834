import styled from 'styled-components';

const Spacing = styled.div`
  height: ${p => p.height && `${p.height}`};
  width: ${p => p.width && `${p.width}`};
  margin: ${p => p.margin && `${p.margin}`};
  padding: ${p => p.padding && `${p.padding}`};
  display: ${p => p.display && `${p.display}`};
  position: ${p => p.position && `${p.position}`};
  align-items: ${p => p.align && `${p.align}`};
  justify-content: ${p => p.justify && `${p.justify}`};
  overflow: ${p => p.overflow && `${p.overflow}`};
  text-align: ${p => p.textAlign && `${p.textAlign}`};
`

export default Spacing;