import { get, ep, post } from './util';

const EPISODEINFO = {
  id: 'h1',
  title: '1. 학교의 예쁜 아이(1)',
  date: '2021.05.20',
  view: 3600,
  like: 2360,
  commentCount: 890,
};

async function getBannerImgs() {
  try {
    const res = await get(ep('/v1/banner'));
    const imgs = await res.json();
    return {imgs};
  } catch(err) {
    return {imgs: [{url: ""}]};
  }
}

async function getAlarm() {
  return {
    title: "공지사항",
    content: "베타 노블 서비스 런칭 1달만에 월억킥 가자!!",
  }
}

async function getNovelAlarm() {
  return {
    title: "공지",
    content: "베타 노블 서비스 런칭 1달만에 월억킥 가자!!",
  }
}

async function getSectionData() {
  try {
    const res = await get(ep('/v1/section'));
    const json = await res.json();

    return json;
  } catch(err) {
    return {err: true};
  }
}

async function getNovel(novelId) {
  try {
    const res = await post(ep('/v1/novel'), {id: novelId});
    const json = await res.json();

    console.log(res, json);
    return json;
  } catch(err) {
    console.error(err);
    return {err: true};
  }
}

async function getEpisodeInfo(episodeId) {
  if(Array.isArray(episodeId)) {
    return Array.from({length: episodeId.length}, e => EPISODEINFO);
  }
  return EPISODEINFO;
}

async function getNovelCategory(day, pro, categoryName) {
  console.log('get novel of category', {day, pro, categoryName});

  try {
    
  } catch(err) {
    console.error(err);
    return {err: true};
  }
}

async function postNewNovel(novel, img64) {
  try {
    const res = await post(ep('/v1/register'), novel);
    const json = await res.json();
    console.log(json);
    await post(ep('/v1/cover'), {id: json.id, img: img64});
    return json;
  } catch(err) {
    return {err: true};
  }
}

export {
  getBannerImgs, 
  getAlarm, 
  getSectionData, 
  getNovelAlarm,
  getNovel,
  getNovelCategory,
  getEpisodeInfo,
  postNewNovel,
};