import { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

import { AuthProvider } from './context/authContext';

import Home from './pages/Home';
import Relay from './pages/Relay';
import Novel from './pages/Novel';
import Editor from './pages/Editor';
import Login from './pages/Login';
import Select from './pages/Join/Select';
import Insert from './pages/Join/Insert';
import Register from './pages/Register';
import MyPage from './pages/MyPage';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  #root {
    width: 100%;
  }

  div {
    margin: 0;
    padding: 0;
  }

`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

`

function App() {
  return (
    <Fragment>
      <GlobalStyle />
      <AuthProvider>
        <AppContainer>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/relay" element={<Relay />} />
            <Route path="/novel/:novelId" element={<Novel />} />
            <Route path="/editor/:episodeId" element={<Editor />} />
            {/* <Route path="/viewer/:episodeId" element={<Viewer />} /> */}
            <Route path="/join" element={<Select />} />
            <Route path="/join/insert" element={<Insert />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/mypage" element={<MyPage type="recent" />} />
            <Route path="/mypage/recent" element={<MyPage type="recent" />} />
            <Route path="/mypage/serial" element={<MyPage type="serial" />} />
            <Route path="/mypage/profit" element={<MyPage type="profit" />} />
            <Route path="/mypage/bill" element={<MyPage type="bill" />} />
            <Route path="/mypage/contract" element={<MyPage type="contract" />} />
          </Routes>
        </AppContainer>
      </AuthProvider>
    </Fragment>
  );
}

export default App;
