import styled from 'styled-components';
import { useState, useEffect } from 'react';

import BasicFrame from '../../frame/Basic';
import WorkCard from '../../components/WorkCard';
import OptionBar, {CATEGORY_DAY} from './OptionBar';

import { getNovelCategory } from '../../api';

const WorkGrid = styled.div`
  width: 100%;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  gap: 34px 0;
`

function Relay() {
  const [isDay, setIsDay] = useState(true);
  const [isFree, setIsFree] = useState(true);
  const [selected, setSelected] = useState('time');
  const [category, setCategory] = useState(CATEGORY_DAY);
  const [selectedCat, setSelectedCat] = useState(0);
  const [novelIds, setNovelIds] = useState(["1"]);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    const fetchWorks = async () => {
      const res = await getNovelCategory(isDay, isFree, category[selectedCat]);
      console.log(res);
      setNovelIds(res);
      setBusy(false);
    }

    fetchWorks();
  }, [isDay, isFree, selectedCat]);

  // busy
  if(busy) 
    return (
      <div></div>
    )
  else {
    return (
      <BasicFrame>
        <OptionBar 
          isDay={isDay} setIsDay={setIsDay} 
          selected={selected} setSelected={setSelected}
          isFree={isFree} setIsFree={setIsFree}
          category={category} setCategory={setCategory}
          selectedCat={selectedCat} setSelectedCat={setSelectedCat}
          workNum={729} />
        <WorkGrid>
          {
            novelIds.map(novelId =>
              <WorkCard key={novelId} id={novelId} useHover={true}/>
            )
          }
        </WorkGrid>
      </BasicFrame>
    )
  }
}

export default Relay;