import { useState } from 'react';
import styled from 'styled-components';
import { useRef } from 'react';

import Col from '../../components/Col';
import Row from '../../components/Row';
import Text from '../../components/Text';

const Container = styled(Col)`
  position: relative;
  flex-grow: 1;
  height: 300px;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  margin-bottom: 60px;
`

const Button = styled.div`
  width: 180px;
  height: 75px;
  text-align: center;
  line-height: 75px;
  font-size: 28px;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  background-color: #8ccda3;
  &:hover {
    cursor: pointer;
  }
`

const ImgHolder = styled(Col)`
  position: relative;
  width: 200px;
  height: 300px;
  background-color: #8CCDA3;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Img = styled.img`
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 300px;
  object-fit: cover;
`

const Info = styled.div`
  position: absolute;
  bottom: 28px;
  font-size: 20px;
  width: 490px;
  text-align: center;
  font-weight: medium;
`;

function ImgUpload({img, setter}) {
  const [state, setState] = useState("empty") // "empty" || "hover" || "file"
  const hiddenFileInput = useRef(null);

  const parser = (files) => {
    if(files.length > 0) {
      let reader = new FileReader();
      reader.onload = (evt) => {
        setter(evt.target.result);
      }

      reader.readAsDataURL(files[0]);
    }
  }

  const dropHandler = (evt) => {
    let files = evt.dataTransfer.files;
    parser(files);
    evt.preventDefault();
  }

  const dragOverHandler = (evt) => {
    evt.preventDefault();
  }

  const handleClick = (evt) => {
    hiddenFileInput.current.click();
  }

  const handleChange = (evt) => {
    let files = evt.target.files;
    parser(files);
  }

  if(state === 'empty') {
    return(
      <Row gap="32px" width="1128px">
        <ImgHolder>
          {img && <Img src={`${img}`} />}
          <Col>
            <Text huge c="white" weight={700}>북커버</Text>
            <Text huge c="white" weight={700}>준비 중</Text>
          </Col>
        </ImgHolder>
        <Container onDrop={dropHandler} onDragOver={dragOverHandler}>
          <input type="file" 
                style={{display: 'none'}} 
                ref={hiddenFileInput} 
                onChange={handleChange}/>
          <Button onClick={handleClick}> 파일 선택 </Button>
          <Info>
            *업로드 할 파일을 선택하거나 업로드 영역으로 드래그 엔 드롭 해주세요.
          </Info>
        </Container>
      </Row>
    )
  } else if(state === 'hover') {
    
  }
}

export default ImgUpload;