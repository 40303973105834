import styled from 'styled-components';

import WorkCard from '../../components/WorkCard';
import Col from '../../components/Col';
import Row from '../../components/Row';
import Text from '../../components/Text';
import Link from '../../components/Link';

const Container = styled(Col)`
  width: 1128px;
  margin: 20px 0;
  gap: 7px;
`

const Novels = styled(Row)`
  width: 1128px;
  gap: 57px;
`

function Section({title, novelIds, showNumber}) {
  return (
    <Container>
      <Row width={1000} jc="space-between" ai="flex-end">
        <Text weight={700} normal>{title}</Text>
        <Link pico to="/">더보기</Link>
      </Row>
      <Novels>
        {
          novelIds.map((novelId, idx) => 
            <WorkCard 
              key={novelId}
              id={novelId} 
              showNumber={showNumber} 
              number={idx+1}/>
          )
        }
      </Novels>
    </Container>
  )
}

export default Section;