import styled from 'styled-components'
import Spacing from './Spacing'

export default styled(Spacing)`
  display: flex;
  width: ${props => props.width};
  flex-direction: row;
  align-self: ${props => props.als};
  align-items: ${props => props.ai};
  justify-content: ${props => props.jc};
  gap: ${props => props.gap};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  border: ${props => props.border};
  flex-wrap: ${props => props.wrap};
`