import styled from 'styled-components';
import { Link } from 'react-router-dom'

import Row from '../../components/Row';
import FullFrame from '../../frame/Full';
import Protected from '../../components/Protected';
import { 
  UserLogo,
  NaverLogo,
  GoogleLogo,
  KakaoLogo,
  EmailLogo,
} from '../../components/Logo';

const Logo = styled.img`
  width: 85px;
  height: 85px;
  margin-bottom: 21px;
`

const Title = styled.div`
  font-size: 20px;
  font-family: SBB;
  margin-bottom: 64px;
`

const LoginService = styled(Row)`
  justify-content: center;
  align-items: center;
  width: 468px;
  height: 35px;
  color: ${props => props.color || "white"};
  background-color: ${props => props.bc || "white"};
  font-size: 14px;
  text-align: center;
  line-height: 33px;
  margin-bottom: 13px;
  font-weight: bold;
  
  & > a {
    text-decoration: none;
    color: white;
  }
  &:hover {cursor:pointer;}
  &:last-child {margin-bottom: 60;}
`

const ServiceLogo = styled.img`
  height: 18px;
  margin-right: 16px;
`

function Select() {
  return (
    <Protected>
    <FullFrame width="628">
      <Logo src={UserLogo}/>
      <Title>회원가입</Title>
      <LoginService bc="#00B3E5">
        <ServiceLogo src={EmailLogo} />
        <Link to="/join/insert">베타노블 계정 생성하기</Link>
      </LoginService>
      <LoginService bc="#03C75A">
        <ServiceLogo src={NaverLogo} />
        네이버 아이디로 가입하기
      </LoginService>
      <LoginService bc="#FEE500">
        <ServiceLogo src={KakaoLogo} />
        카카오 아이디로 가입하기
      </LoginService>
      <LoginService bc="#F16438">
        <ServiceLogo src={GoogleLogo} />
        구글 아이디로 가입하기
      </LoginService>
    </FullFrame>
    </Protected>
  )
}

export default Select;