import TopBar from '../components/TopBar';
import Col from '../components/Col';

function BasicFrame(props) {
  return (
    <Col ai="center">
      <TopBar />
      <Col width="1128px" ai="center">
        {props.children}
      </Col>
    </Col>
  )
}

export default BasicFrame;