import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import Col from "../../components/Col";
import Row from "../../components/Row";
import Text from "../../components/Text";
import { AuthContext } from '../../context/authContext';
import { _Novel } from '../../api/schema';
import { ep, post } from '../../api/util';
import Badge from '../../components/Badge';

const NovelWrapper = styled(Row)`
  padding: 12px 0;
  border-top: 1px solid black;
`
const Button = styled.div`
  position: absolute;
  width: 120px;
  height: 40px;

  background: #00B3E5;
  border-radius: 5px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 700;
`

function Serial() {
  const [busy, setBusy] = useState(true);
  const [serial, setSerial] = useState([_Novel]);
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      console.log(authState.token);
      const data = await post(
        ep('/v1/mypage/serial'), 
        {token: authState.token});
      const json = await data.json();
      setSerial(json);
      console.log(json);
      if(json.err !== true)
        setBusy(false);
    }

    fetchData();
  }, []);

  if(busy)
    return (
      <div></div>
    )
  return (
    <Col width="100%">
      <Text sbm small>내가 참가한 작품</Text>
      {
        serial.map(novel =>
          <NovelWrapper>
            <img width={140} height={210} src={ep(`/static/img/cover/${novel.id}`)} />
            <Col gap="12px">
              <Row gap="12px">
                <Text big>{novel.title}</Text>
                <Text small>{novel.writer}</Text>
              </Row>
              <Row gap="12px">
                <Text small>조회수 {novel.view}회</Text> 
                <Text small>회차 {novel.episodeCount}회</Text> 
                <Text small>좋아요 {novel.like}개</Text>
              </Row>
              <Row gap="9px">
                <Text small>태그</Text>
                {
                  novel.tags.map(t => <Badge bc="#9FE3B7" c="black" text={t} />)
                }
              </Row>
            </Col>
            <Col als="flex-end" width="120px">
              <Button>정보 수정</Button>
            </Col>
          </NovelWrapper>
        )
      }
    </Col>
  )
}

export default Serial;