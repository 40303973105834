import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { getBannerImgs } from '../../api';
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa';

import Col from '../../components/Col';
import Row from '../../components/Row';

const Container = styled(Col)`
  width: 1256px;
  margin-bottom: 50px;
  align-items: center;
`

const BannerImg = styled.img`
  width: 1256px;
  height: 340px;  
  border-radius: 12px;
  margin-bottom: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  object-fit: cover;
`

const Controllers = styled(Row)`
  justify-content: space-around;
  align-items: center;
`

const Slider = styled.div`
  width: 1064px;
  border-top: solid #AAAAAA 1px;
  margin-right: 20px;
`

const PosSlider = styled.div`
  position: absolute;
  margin-left: ${props => props.left}px;
  width: ${props => props.size}px;
  border-top: solid #000000 2px;
`

const Control = styled.div`
  &:hover {
    cursor: pointer;
  }
`

// TODO: slider animation
function Banner() {
  const [imgs, setImgs] = useState([{url: ""}]);
  const [idx, setIdx] = useState(0);
  const [len, setLen] = useState(1);

  useEffect(() => {
    const fetchImgs = async() => {
      const res = await getBannerImgs();
      setImgs(res.imgs);
      setLen(res.imgs.length);
    }

    fetchImgs();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIdx(idx => (idx+1)%len);
    }, 3000);

    return () => clearInterval(interval);
  }, [len]);


  return (
    <Container>
      <BannerImg src={imgs[idx].url} alt="BannerImage" />
      <Controllers>
        <Slider>
        <PosSlider left={1064/len*idx} size={1064/len}/>
        </ Slider>
        <Control onClick={() => setIdx((idx - 1 + len) % len)}>
          <FaAngleLeft size={24}/>
        </Control>
        <Control onClick={() => setIdx((idx + 1) % len)}>
          <FaAngleRight size={24}/>
        </Control>
      </Controllers>
    </Container>
  )
}

export default Banner;