import { useState } from "react";
import styled from "styled-components";
import { FaPlus } from 'react-icons/fa';
import { useContext } from 'react';
import { RiQuestionLine } from 'react-icons/ri';
import { GiCancel } from 'react-icons/gi';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

import BasicFrame from '../../frame/Basic';
import Text from '../../components/Text';
import Row from '../../components/Row';
import Col from '../../components/Col';
import ImgUpload from './ImgUpload';
import Badge from '../../components/Badge';
import { postNewNovel } from "../../api";
import { AuthContext } from '../../context/authContext';
import { useNavigate } from "react-router-dom";

const TitleInput = styled.input`
  width: 100%;
  margin: 18px 0 32px;
  padding-bottom: 8px;
  font-size: 32px;
  border: none;
  overflow: auto;
  outline: none;
  border-bottom: 1px solid black;
`

const Title = styled(Text)`
  align-self: flex-start;
  margin-bottom: 11px;
`;

const IntroInput = styled.textarea`
  width: 100%;
  min-height: 300px;
  font-size: 20px;
  resize: vertical;
  font-weight: regular;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border: none;
  overflow: auto;
  outline: none;
  border-bottom: 1px solid black;
`

const Fixed = styled(Col)`
  position: fixed;
  padding: 5px;
  top: ${props => props.Y}px;
  left: ${props => props.X}px;
  background: #E2E2E2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &:hover {
    cursor: default;
  }
`

const Button = styled(Row)`
  background: #8CCDA3;
  border-radius: 10px;

  width: 165px;
  height: 55px;
  
  margin-bottom: 50px;
`

const Register = () => {
  let navigate = useNavigate();
  const { authState, setAuth } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [img, setImg] = useState("");
  const [isProfit, setProfit] = useState(false);
  const [isMonopoly, setMonopoly] = useState(false);
  const [category, setCategory] = useState([]);
  const [tag, setTag] = useState([]);
  const [day, setDay] = useState([false, false, false, false, false, false, false]);
  const [intro, setIntro] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [pos, setPos] = useState({X: 0, Y: 0});
  const [show, setShow] = useState(false);
  const [busy, setBusy] = useState(false);
  const DAYS = ['월', '화', '수', '목', '금', '토', '일'];
  
  const handleSubmit = () => {
    // validate input
    setBusy(true);
    // post to server
    let novel = {
      token: authState.token,
      title,
      introduction: intro,
      category: category[0],
      tags: tag,
      isProfit,
      isMonopoly,
      cycle: day,
      opened: isOpen,
    }
    const postData = async() => {
      await postNewNovel(novel, img);
      setBusy(false);

      navigate('/mypage/serial');
    }
    postData();
  }

  // if busy show loading
  return (
    <BasicFrame>
      <TitleInput  
        placeholder="작품명을 입력하세요" 
        value={title}
        onChange={(evt) => {
          setTitle(evt.target.value);
        }}/>
      <Title normal sbm>북커버</Title>
      <ImgUpload img={img} setter={setImg} />
      <Row gap="60px" width="1128px" margin="0 0 65px">
        <CheckBox setter={setProfit} value={isProfit} label={'수익'} />
        <CheckBox setter={setMonopoly} value={isMonopoly} label={'독점작'} />
      </Row>
      <Col gap="60px" als="flex-start" width="100%">
        <Picker values={category} setter={setCategory} title="카테고리" />
        <Picker values={tag} setter={setTag} title="태그" />
        <Col gap="12px" als="flex-start">
          <Text normal sbm>연재 주기</Text> 
          <Row gap="60px">
            {
              DAYS.map((dayName, idx) => (
                <Row ai="center">
                  <input 
                    type="checkbox" 
                    value={day[idx]} 
                    onClick={() => setDay(s => {
                      s[idx] = !s[idx];
                      return s;
                    })} />
                  <Text small>{dayName}</Text>
                </Row>
              ))
            }
          </Row>
        </Col>
        <Col gap="8px">
          <Text normal sbm>소개</Text>
          <IntroInput placeholder="내용 입력란" value={intro} onChange={evt => setIntro(evt.target.value)} />
        </Col>
      </Col>
      <Col gap="8px" als="flex-start" margin="60px 0 0">
        <Row gap="12px">
          <Text normal sbm>오픈드</Text>
          <Toggle 
            defaultChecked={isOpen} 
            onChange={e => setOpen(e.target.checked)} 
            icons={false}
            color="#8CCDA3" />
          <Text normal sbm>클로즈드</Text>
        </Row>
        <Text small 
          onMouseMove={(evt) => setPos({X: evt.clientX, Y: evt.clientY})}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}>
          <RiQuestionLine />
          오픈드 / 클로즈드는 뭔가요?
        </Text>
        {
          show && <Fixed X={pos.X} Y={pos.Y}>
            <Text>
              오픈드란, 회원 누구나 회차 도전이 가능한 옵션입니다.
            </Text>
            <Text>
              클로즈드란, 파운더가 크루원을 직접 초대해서 제한된 사람들만 회차 도전이 가능한 옵션입니다.
            </Text>
          </Fixed>
        }
      </Col>
      <Button onClick={handleSubmit} jc="center" ai="center" als="flex-end">
        <Text normal weight={800} c="white">등록</Text> 
      </Button>
    </BasicFrame>
  )
}

function CheckBox({setter, value, label}) {
  return (
    <Row ai="center">
      <input type="checkbox" value={value} onClick={() => setter(i => !i)}/> 
      <Text small>{label}</Text>
    </Row>
  )
}

// const Append

const Input = styled.input`
  width: 133px;
  height: 40px;
  font-size: 36px;
  line-height: 40px;
  padding: 0 8px;
`

function Picker({values, setter, title}) {
  const [item, setItem] = useState("");

  return (
    <Col gap="8px" als="flex-start">
      <Text normal sbm>{title}</Text> 
      <Row wrap="wrap" ai="center" gap="8px">
        <Input 
          type="text" 
          value={item} 
          onChange={evt => setItem(evt.target.value)} />
        <Text normal weight={800} onClick={() => {
          if(item !== "") {
            setter(s => s.concat([item]));
            setItem("");
          }
        }}><FaPlus /></Text>
        {
          values.map(value => <Badge bc="#8CCDA3" c="black" text={(
            <Row ai='center'>
              <Text normal sbm>
                {value} 
              </Text>
              <GiCancel size="24px" onClick={
                () => setter(s => s.filter(v => v!==value))
              } />
            </Row>
          )} />)
        }
      </Row>
    </Col>
  )
}

export default Register;