import { useEffect, useState } from 'react';

import BasicFrame from '../../frame/Basic';
import Banner from './Banner';
import Section from './Section';
import AlarmBar from '../../components/AlarmBar';

import { getAlarm, getSectionData } from '../../api';
import { _Section } from '../../api/schema';

function Home() {
  const [alarm, setAlarm] = useState({title: "", content: ""});
  const [sections, setSections] = useState([_Section]);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    const fetchDatas = async () => {
      const alarmData = await getAlarm();
      const sectionData = await getSectionData();

      setAlarm(alarmData);
      setSections(sectionData);
      setBusy(false);
    }
    
    fetchDatas();
  }, []);

  if(busy)
    return (
      <div></div>
    )
  else
    return (
      <BasicFrame>
        <Banner />
        <AlarmBar width={1128} title={alarm.title} content={alarm.content}/>
        {
          sections.map((section) => 
            <Section key={section.title} {...section}/>
          )
        }
      </BasicFrame>
    )
}

export default Home;