import styled from 'styled-components';

import Col from '../components/Col';

const Container = styled(Col)`
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #8CCDA3;
`

const Box = styled(Col)`
  align-items: center;
  background-color: white;
  padding: 16px 0px;
  background: #FFFFFF;
  border-radius: 42px;
  ${props => props.width && `
    width: ${props.width}px;
    padding: 33px 0;  
  `}
`


function FullFrame(props) {
  return (
    <Container>
      <Box width={props.width}>
        {props.children}
      </Box>
    </Container>
  )
}

export default FullFrame;